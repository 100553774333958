import api from "./api";

export const fetchData = async (token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const response = await api.get("api/de", config);
        return (response.data);
    } catch (error) {
        throw error;
    }
}

export const getKude = async (id, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const response = await api.get(`api/de/kude/${id}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getXml = async (id, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const response = await api.get(`api/de/xml/${id}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const sendDE = async (id, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        await api.post(`api/dispatch/de/${id}`, {}, config)
    } catch (error) {
        throw error;
    }
}

export const consultarLoteDE = async (cdc, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        await api.get(`api/eventos/consultaLoteDE/${cdc}`, config)
    } catch (error) {
        throw error;
    }
}

export const consultarExistenciaDE = async (cdc, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        await api.get(`api/eventos/consultaExistenciaDE/${cdc}`, config)
    } catch (error) {
        throw error;
    }
}

export const getDEs = async (opciones, token) => {
    try {

        const jsonDEQuery = {
            Buscador: opciones?.buscador || '',
            TipoDoc: opciones?.tipoDoc || null,
            Establecimiento: opciones?.establecimiento || null,
            PuntoEmision: opciones?.puntoEmision || null,
            EstadoInicial: opciones?.estadoInicial || null,
            EstadoProcesado: opciones?.estadoProcesado || null,
            Estado: opciones?.estado || null,
            DesdeFechaEmision: opciones?.desdeFechaEmision || null,
            HastaFechaEmision: opciones?.hastaFechaEmision || null,
            DesdeFechaProcesado: opciones?.desdeFechaProcesado || null,
            HastaFechaProcesado: opciones?.hastaFechaProcesado || null,
            CurrentPage: opciones?.currentPage || null,
            PageSize: opciones?.pageSize || null
        }

        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const response = await api.post("api/de/query", jsonDEQuery, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getListDEPaginated = async (query, token) => {
    try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        console.log("api/de?" + query)

        const response = await api.get("api/de?" + query, config);
        return response.data;
    } catch (error) {
        throw error;
    }

}