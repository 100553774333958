import React from 'react';
import { NavMenu } from '../components/NavMenu';
import { useContext } from "react";
import { AuthContext } from "../hooks/auth/AuthContext";
import '../styles/layout-styles.css'

const Layout =(props) => {
  const authContext = useContext(AuthContext);
  const {user, logout} = authContext;

  return (
    <div className='div-root'>
      <div className='header'>
        <NavMenu user={user} logout={logout}/>
      </div>
      <div className='body'>
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
