export const EstadosInicialEnum = {
    NoEnviado: 0,
    Procesado: 1,
    EnSifen: 2,
    ErrorEnvio: 4
}

export const EstadosProcesoEnum = {
    Aprobado: 0,
    AprobadoConObservacion: 1,
    Rechazado: 2,
    EnProceso: 3,
}

export const EstadosEnum = {
    Inactivo: 0,
    Activo: 1,
    Cancelado: 2,
    Inutilizado: 3,
    Devuelto: 4
}

export const TiposDocEnum = {
    Factura: 1,
    AutoFactura: 4,
    NotaDeCredito: 5,
}

export const TiposDocAsoEnum = {
    Electronico: 1,
    Impreso: 2,
    ConstanciaElectronica: 3
}

export const TipoConsDocAsoEnum = {
    NoContribuyente: 1,
    Microproductores: 2
}