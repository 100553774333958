import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "../styles/NavMenu.css";
import { config } from "../utils/config";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          container
          light
        >
          <NavbarBrand tag={Link} to="/">
            Facturación Electronica :: {config.NOMBRE_EMPRESA} ::{" "}
            {config.VERSION} {config.ENTORNO}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!this.state.collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              {!this.props.user ? (
                <></>
              ) : (
                <>
                  <NavItem>
                    <div className="nav-user-data">
                      <p>{this.props.user.fullName}</p>
                      <Button
                        variant="secondary"
                        onClick={() => this.props.logout()}
                      >
                        Cerrar Sessión
                      </Button>
                    </div>
                  </NavItem>
                </>
              )}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
