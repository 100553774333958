import React from "react";
import { Pagination } from "react-bootstrap";
import "../styles/pagination-styles.css";

const PaginationNav = ({
  currentPage,
  handleCurrentPage,
  itemsPerPage,
  totalItems,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePagination = (newPage) => {
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    handleCurrentPage(newPage);
  };

  const renderPagination = () => {
    var maxPagesToShow = 10;
    if (window.innerWidth < 768) {
      maxPagesToShow = 5;
    }

    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Renderiza el botón "First"
    pages.push(
      <Pagination.First key="first" onClick={() => handlePagination(1)} />
    );

    // Renderiza el botón "Prev"
    pages.push(
      <Pagination.Prev
        key="prev"
        onClick={() => handlePagination(currentPage - 1)}
      />
    );

    // Renderiza el elipsis si es necesario
    if (startPage > 1) {
      pages.push(<Pagination.Ellipsis key="ellipsisStart" disabled />);
    }

    // Renderiza las páginas
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          className={i === currentPage ? "active-page" : ""}
          onClick={() => handlePagination(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Renderiza el elipsis si es necesario
    if (endPage < totalPages) {
      pages.push(<Pagination.Ellipsis key="ellipsisFinish" disabled />);
    }

    // Renderiza el botón "Next"
    pages.push(
      <Pagination.Next
        key="next"
        onClick={() => handlePagination(currentPage + 1)}
      />
    );

    // Renderiza el botón "Last"
    pages.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePagination(totalPages)}
      />
    );

    return pages;
  };

  return (
    <>{totalPages !== 0 && <Pagination>{renderPagination()}</Pagination>}</>
  );
};

export default PaginationNav;
