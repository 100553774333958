import React, { forwardRef } from "react";
import { BiSolidCalendarMinus } from "react-icons/bi";
import { BiSolidCalendarPlus } from "react-icons/bi";

const CustomInputDate = forwardRef((props, ref) => {
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
        ref={ref}
        placeholder={props.placeholder}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          {props.isInitial ? <BiSolidCalendarMinus /> : <BiSolidCalendarPlus />}
        </span>
      </div>
    </div>
  );
});

export default CustomInputDate;
