import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Alert, Container, Form, Spinner } from "reactstrap";
import useAuth from "../../hooks/auth/useAuth";
import Head from "../../components/layout/head/Head";
import {AiFillEye} from 'react-icons/ai'
import {AiFillEyeInvisible} from 'react-icons/ai'
import { Button } from 'react-bootstrap';

import '../../styles/login-page-styles.css';
import '../../styles/icon-input-styles.css'

const Login = () => {
  const [passState, setPassState] = useState(false);
  const { login, error } = useAuth();

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (data) => {
    setLoading(true);
    try {
      await login(data.name, data.passcode);

      } catch (error) {
      setErrorMessage("Credenciales inválidas");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {

      setErrorMessage("Credenciales inválidas");
      reset();

      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error, reset]);

  return (
    <>
      <Head title="Login" />
      <Container className="container_main">
        <div>
          <div>
            <div>
              <h3>Inicio de Sesión</h3>
              <div>
                <p>Bienvenido a Facturación Electronica</p>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div>
              <Alert color="danger" className="alert-icon">
                {errorMessage}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(handleLogin)}>
            <div className="form-group">

              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register("name", { required: "Este campo es requerido" })}
                  placeholder="Ingresa tu correo electrónico"
                  className="form-control-lg form-control"
                />
                {errors.name && (
                  <span className="invalid">{errors.name.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                {/*<Link*/}
                {/*  className="link link-primary link-sm"*/}
                {/*  to={`${process.env.PUBLIC_URL}/auth-reset`}*/}
                {/*>*/}
                {/*  ¿Olvidaste tu contraseña?*/}
                {/*</Link>*/}
              </div>
              <div className="form-control-wrap">
                <div className="custom-input">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className="icon"
                  > 
                    {passState ? <AiFillEyeInvisible/>: <AiFillEye/>}
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    {...register("passcode", {
                      required: "Este campo es requerido",
                    })}
                    placeholder="Ingresa tu contraseña"
                    className={`form-control-lg form-control`}
                  />
                </div>
                {errors.passcode && (
                    <span className="invalid">{errors.passcode.message}</span>
                  )}  
              </div>
            </div>
            <div className="form-group">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  "Iniciar sesión"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Login;
