import React from 'react';
import { AuthContext } from "./hooks/auth/AuthContext";
import useAuth from "./hooks/auth/useAuth";
import Pages from "./route/Index.js";
import { Route, Routes} from 'react-router-dom';
import Router from './route/Index.js'
import  Layout  from './pages/Layout.js';

const App = () => {
    const auth = useAuth();
    
    return (
      <AuthContext.Provider value={auth} >
        <Layout>
          <Router>
            <Routes>
              <Route path="/*" element={<Pages />} />
            </Routes>
          </Router>
        </Layout>
      </AuthContext.Provider>
    );
}

export default App

