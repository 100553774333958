import React from 'react';
import { AuthContext } from "../hooks/auth/AuthContext";
import Login from '../pages/auth/Login'
import {Route, Routes   } from 'react-router-dom';
import Home from '../pages/Home'
import { useContext } from "react";

const Pages = () => {
    const authContext = useContext(AuthContext);
    const {user} = authContext;
    
    const userHasRole = (requiredRoles) => {
      if (!user || !user.roles) {
        return false;
      }
    
      if (typeof requiredRoles === 'string') {
        return user.roles === requiredRoles;
      }
    
      if (Array.isArray(requiredRoles)) {
        return requiredRoles.includes(user.roles);
      }
    
      return false;
    };

    return (
        <Routes>
          
          {/* Rutas Protegidas */}
          {userHasRole(["Administrador", "user"]) && (
            <Route path="/home" element={<Home/>} />
          )}

          {user ? <Route path="*" element={<Home />} /> : 
          <>
            <Route path="/auth-login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>}
        </Routes>
    );
};

export default Pages;
