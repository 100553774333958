export const getData = (data) => {
    //console.log(data)

    data = data.map(item => ({
        ...item,
        camposProcesadoDE: JSON.parse(item.jsonDEProcesado),
        historial: JSON.parse(item.historialRespuestasSifen)
    }))

    return data.map(item => ({
        ...item,
        fechaEmision: item.camposProcesadoDE.DFeEmiDE,
        nombreCliente: item.camposProcesadoDE.Receptor.DNomRec,
        totalDoc: item.camposProcesadoDE.DTotOpe,
        monedaDoc: item.camposProcesadoDE.CMoneOpe,
        tipoDoc: item.camposProcesadoDE.DDesTiDE,
        rucCliente: item.camposProcesadoDE.Receptor.DRucRec,
        dvRucCliente: item.camposProcesadoDE.Receptor.DDVRec,
        cinCliente: item.camposProcesadoDE.Receptor.DNumIDRec,
        timbradoDoc: item.camposProcesadoDE.DNumTim,
        establecimiento: item.camposProcesadoDE.DEst,
        puntoEmision: item.camposProcesadoDE.DPunExp,
        tipoDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DDesTipDocAso : "",
        cdcDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DCdCDERef : "",
        codTipoDoc: item.camposProcesadoDE.ITiDE,
        codTipoDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].ITipDocAso : "",
        timbradoDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DNTimDI : "",
        numDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DNumDocAso : "",
        tipoConsDocAso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DDesTipCons : "",
        tipoDocAsoImpreso: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DDTipoDocAso : "",
        natCliente: item.camposProcesadoDE.Receptor.INatRec,
        serieDoc: item.serieDocumento,
        nombreVendedorAFE: item.camposProcesadoDE.AutoFactura?.DNomVen,
        iTipCons: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].ITipCons : null,
        dNumCons: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DNumCons : "",
        dNumControl: item.camposProcesadoDE.ListDocumentoAsociado ? item.camposProcesadoDE.ListDocumentoAsociado[0].DNumControl : "",


    }));
};

//Obtener Valores
//Funciones Privadas
/*const getStringOfXml= (xmlString,nodeName) => {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xmlString, "text/xml");
    
    // Obtenemos el nodo
    let nodo = xmlDoc.getElementsByTagName(nodeName)[0];

    //Verificamos que nodo exista y retornamos el valor
    if(nodo !== undefined){
        let value = xmlDoc.getElementsByTagName(nodeName)[0].childNodes[0].nodeValue;
        return value
    }
    //No existe el nodo
    return null;
}
const getFloatOfXml = (xmlString,nodeName) =>{
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xmlString, "text/xml");
    
    // Acceder al valor del nodo 
    let valor = parseFloat(xmlDoc.getElementsByTagName(nodeName)[0].childNodes[0].nodeValue);
    return valor;
}*/

