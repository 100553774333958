import React from "react";
import "../styles/empty-message-styles.css";

const EmptyMessage = () => {
  return (
    <div className="divEmpty">
      <p>
        ¡Ups! Parece que no hay resultados. Intenta ajustar tus criterios de
        búsqueda.
      </p>
    </div>
  );
};

export default EmptyMessage;
