
// baseUrl: 'https://localhost:7265',
// baseUrl: 'http://localhost:80',
// baseUrl: 'http://facturaelectronica.brazilsouth.cloudapp.azure.com',    
// baseUrl: 'https://ebm-kasas-test.azurewebsites.net',
// baseUrl: 'https://test-fe-kasas.tenondesoft.com',
// baseUrl: 'https://test-fe-real.tenondesoft.com',
// baseUrl: 'https://prod-fe-kasas.tenondesoft.com',

const apiConfig = {
    baseUrl: 'https://test-fe-real.tenondesoft.com',
  };
  
  export default apiConfig;