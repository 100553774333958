import React from "react";
import "../styles/text-box-styles.css";

const TextBox = ({ variant, ...props }) => {
  const getStyles = () => {
    switch (variant) {
      case "dark":
        return "box-dark";
      default:
        return "box-default";
    }
  };

  return (
    <div className="container">
      <input
        value={props.value !== null ? props.value : ""}
        className={getStyles()}
        readOnly={true}
      ></input>
    </div>
  );
};

export default TextBox;
