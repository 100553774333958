import Button from "react-bootstrap/Button";
import { Modal, Tabs, Tab} from "react-bootstrap";
import xmlFormatter from "xml-formatter";
import "../styles/modal-details-styles.css";
import { consultarExistenciaDE, consultarLoteDE, getKude, getXml, sendDE } from "../services/dataService";
import { useContext} from "react";
import { AuthContext } from "../hooks/auth/AuthContext";
import "../styles/loader-styles.css";
import { formatDateTime } from "../utils/dataFormatter";
import { EstadosEnum, EstadosInicialEnum, TipoConsDocAsoEnum, TiposDocAsoEnum, TiposDocEnum } from "../utils/enums";
import ItemDetailDE from "./ItemDetailDE";

const ModalDetailsDE = ({ handleRefresh, loading, setLoading, ...props }) => {
  const { show, item, onClose } = props;

  const authContext = useContext(AuthContext);
  var { user } = authContext;

  const handleDownloadPDF = (base64Data) => {
    fetch(`data:application/pdf;base64,${base64Data}`)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${item.cdc}.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error("Ocurrio un error al descargar el KuDE.", error);
        alert("Ocurrio un error al descargar el KuDE");
      });
  };

  const handleDownloadKude = (id) => {
    const shouldDownload = window.confirm(
      "¿Estás seguro de que deseas descargar el pdf del KuDE?"
    );

    if (shouldDownload) {
      const loadData = async () => {
        try {
          setLoading(true);
          const kudeObtenido = await getKude(id, user.token);
          setLoading(false);
          handleDownloadPDF(kudeObtenido);
        } catch (error) {
          setLoading(false);
          console.error("Ocurrio un error al obtener el KuDE:", error);
          alert("Ocurrio un error al obtener el KuDE");
        }
      };
      loadData();
    }
  };

  const handleDownloadXml = () => {
    const shouldDownload = window.confirm(
      "¿Estás seguro de que deseas descargar el archivo XML?"
    );

    if (shouldDownload) {
      const loadData = async () => {
        try {
          setLoading(true);
          const xmlObtenido = await getXml(item.id, user.token);

          const blob = new Blob([xmlFormatter(xmlObtenido)], {
            type: "text/xml",
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${item.cdc}.xml`;
          a.click();
          URL.revokeObjectURL(url);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Ocurrio un error al obtener el Xml:", error);
          alert("Ocurrio un error al obtener el Xml");
        }
      };
      loadData();
    }
  };

  const handleSendDE = (id) => {

    if (item.estadoInicial !== EstadosInicialEnum.NoEnviado) {
      alert("No se puede realizar el envio del DE por el estado inicial actual");
      return;
    }

    const shouldDownload = window.confirm("¿Estás seguro de que deseas enviar el DE a la SIFEN?");

    if (shouldDownload) {
      const sendData = async () => {

        setLoading(true);

        await sendDE(id, user.token)
        .catch((error) => {
          setLoading(false);
          console.error("Ocurrio un error al enviar el DE: ", error.response.data);
          alert("Ocurrio un error al enviar el DE: \n" + error.response.data);

        }).finally(() => {
          handleRefresh(true);
        });
      };
      sendData();
    }
  };

  const handleConsultarLoteDE = (cdc) => {
    
    if (item.estadoInicial !== EstadosInicialEnum.EnSifen) {
      alert("No se puede realizar la consulta del lote del DE por el estado inicial actual.");
      return;
    }

    const shouldDownload = window.confirm("¿Estás seguro de que deseas consultar el lote del DE a la SIFEN?");
    
    if (shouldDownload) {
      const consultarData = async () => {
          
        setLoading(true);

        await consultarLoteDE(cdc, user.token)
        .catch((error) => {
          alert(error.response.data)
          setLoading(false)

        }).finally(() => {
          handleRefresh(true);
        });
      };

      consultarData();
    }
  };

  const handleConsultarExistenciaDE = () => {

    if (item.estado !== EstadosEnum.Inactivo) {
      alert("No se puede realizar la consulta de la existencia del DE por el estado actual.");
      return;
    } 
    else if (item.estadoInicial === EstadosInicialEnum.EnSifen) {
      alert("No se puede realizar la consulta de la existencia del DE por el estado inicial actual.");
      return;
    }

    const shouldConsulta = window.confirm("¿Estás seguro de que deseas consultar la existencia del DE a la SIFEN?");

    if (shouldConsulta) {
      const consultarData = async () => {
        setLoading(true);

        await consultarExistenciaDE(item.cdc, user.token)
        .catch((error) => {
          alert(error.response.data)
          setLoading(false)
        })
        .finally(() => {
          handleRefresh(true);
        });
      };

      consultarData();
    }

  }

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        contentClassName={loading ? "main-modal-loading" : "main-modal"}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>{item.tipoDoc}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="tabDatos"
            className={loading ? "tab-loading" : "tab"}
            variant="tabs"
            transition={false}
          >
            <Tab eventKey="tabDatos" title="Datos">
              <div className="containerLabels">
                <div>
                  <h6>Documento Eléctronico</h6>
                  <ItemDetailDE title="CDC" value={item.cdc} loading={loading}/>
                  <ItemDetailDE title="Doc. Num." value={item.numeroDocumento} loading={loading}/>
                  <ItemDetailDE title="Timbrado" value={item.timbradoDoc} loading={loading}/>
                  <ItemDetailDE title="Establecimiento" value={item.establecimiento} loading={loading}/>
                  <ItemDetailDE title="Punto de Emisión" value={item.puntoEmision} loading={loading}/>
                </div>
                <div>
                  <h6>{item.camposProcesadoDE.ITiDE === TiposDocEnum.AutoFactura ? "Vendedor" : "Cliente"}</h6>
                  <ItemDetailDE title="Nombre" value={item.camposProcesadoDE.ITiDE === TiposDocEnum.AutoFactura ? item.nombreVendedorAFE : item.nombreCliente} loading={loading}/>
                  <ItemDetailDE title="RUC/CIN" value={item.camposProcesadoDE.ITiDE === TiposDocEnum.AutoFactura ? (item.camposProcesadoDE.AutoFactura.DNumIDVen) : (item.rucCliente ? `${item.rucCliente}-${item.dvRucCliente}` : item.cinCliente)} loading={loading}/>
                  <ItemDetailDE title="Naturaleza" value={item.camposProcesadoDE.ITiDE === TiposDocEnum.AutoFactura ? item.camposProcesadoDE.AutoFactura.DDesNatVen : item.natCliente} loading={loading}/>
                  <ItemDetailDE title="Pago Id" value={item.pagoId} loading={loading}/>
                </div>
                <div>
                  <h6>SIFEN Procesamiento</h6>
                  <ItemDetailDE title="Codigo" value={item.codigoProceso} loading={loading}/>
                  <ItemDetailDE title="Estado" value={item.tituloProceso} loading={loading}/>
                  <ItemDetailDE title="Descripcion" value={item.descripcionProceso} loading={loading}/>
                </div>

                {item.codTipoDoc === TiposDocEnum.AutoFactura ||
                item.codTipoDoc === TiposDocEnum.NotaDeCredito ? (
                  <div>
                    <h6>Documento Asociado</h6>
                    <ItemDetailDE title="Tipo" value={item.tipoDocAso} loading={loading}/>

                    {item.codTipoDocAso === TiposDocAsoEnum.Electronico ? (

                      <ItemDetailDE title="CDC" value={item.cdcDocAso} loading={loading}/>

                    ) : item.codTipoDocAso === TiposDocAsoEnum.Impreso ? (

                      <>
                        <ItemDetailDE title="Doc. Impreso" value={item.tipoDocAsoImpreso} loading={loading}/>
                        <ItemDetailDE title="Doc. Num." value={item.numDocAso} loading={loading}/>
                      </>

                    ) : item.codTipoDocAso === TiposDocAsoEnum.ConstanciaElectronica ? (

                      <>
                        <ItemDetailDE title="Tipo Constancia" value={item.tipoConsDocAso} loading={loading}/>
                    
                        {item.iTipCons === TipoConsDocAsoEnum.Microproductores ? (
                          <>
                            <ItemDetailDE title="Número" value={item.dNumCons} loading={loading}/>
                            <ItemDetailDE title="Número de Control" value={item.dNumControl} loading={loading}/>
                          </>
                        ) : null}
                      </>

                    ) : null}
                  </div>
                ) : null}

                {item.pagoId ? 
                (<div>
                  <h6>Observaciones</h6>
                  <ItemDetailDE title="Contrato Lote Nro" value={item.observaciones} loading={loading}/>
                </div>)
                : null}

              </div>
            </Tab>
            <Tab eventKey="tabHistorial" title="Historial de Envíos">
              <div className="tab-historial-content">
                {Array.isArray(props.item.historial) ? (
                  props.item.historial.map((item, index) => (
                    <div className="itemHistorial" key={index}>
                      <b>Fecha:</b> {formatDateTime(new Date(item.Fecha))},
                      <></>
                      <b>Código:</b> {item.Codigo}, <> </>
                      <b>Título:</b> {item.Titulo}, <> </>
                      <b>Descripción:</b> {item.Descripcion}
                    </div>
                  ))
                ) : (
                  <p>El Historial de los envíos a SIFEN esta vacío</p>
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => handleDownloadKude(item.id)}
            disabled={loading}
          >
            Descargar KuDE
          </Button>
          <Button
            variant="success"
            onClick={() => handleDownloadXml()}
            disabled={loading}
          >
            Descargar XML
          </Button>

          <Button
            variant="primary"
            className="btnSendDE"
            onClick={() => handleSendDE(item.id)}
            disabled={loading}
          >
            Enviar DE
          </Button>
          <Button
            variant="dark"
            onClick={() => handleConsultarLoteDE(item.cdc)}
            disabled={loading || item.estadoInicial !== EstadosInicialEnum.EnSifen}
          >
            Consultar Lote
          </Button>
          <Button
            variant="dark"
            onClick={() => handleConsultarExistenciaDE(item.cdc)}
            disabled={loading || item.estado !== EstadosEnum.Inactivo || item.estadoInicial === EstadosInicialEnum.EnSifen}
          >
            ¿ Existe DE ?
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDetailsDE;
