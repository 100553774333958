import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/filter-form-styles.css";
import { FaCaretDown } from "react-icons/fa";
import { AiFillCaretUp } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { BsFillSendFill } from "react-icons/bs";
import { PiMagnifyingGlassFill } from "react-icons/pi";
import { TbSquareCheckFilled } from "react-icons/tb";
import { FaComputer } from "react-icons/fa6";
import { HiMiniBuildingStorefront } from "react-icons/hi2";
import CustomInputDate from "./CustonInputDate";
import { FechaDesdeEmisionDocsDefault as DesdeFechaEmisionDocsDefault } from "../../utils/utils";

const FilterForm = ({ filterData, setFilterData, onFilterSubmit }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateDesdeEmiChange = (date) => {
    setFilterData((prevData) => ({ ...prevData, desdeFechaEmision: date }));
  };

  const handleDateHastaEmiChange = (date) => {
    setFilterData((prevData) => ({ ...prevData, hastaFechaEmision: date }));
  };

  const handleDateDesdeProcChange = (date) => {
    setFilterData((prevData) => ({ ...prevData, desdeFechaProcesado: date }));
  };

  const handleDateHastaProcChange = (date) => {
    setFilterData((prevData) => ({ ...prevData, hastaFechaProcesado: date }));
  };

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  const showFilter = () => {
    setIsVisibleFilter(true);
  };

  const hideFilter = () => {
    setIsVisibleFilter(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onFilterSubmit(1);
    }
  };

  return (
    <>
      {isVisibleFilter ? (
        <div>
          <div className="divButtonAddFilter">
            <div>
              <Button
                variant="dark"
                onClick={hideFilter}
                className="btnFiltros"
              >
                Filtros <AiFillCaretUp />
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  setFilterData({
                    buscador: "",
                    tipoDoc: "",
                    establecimiento: "",
                    puntoEmision: "",
                    estadoInicial: "",
                    estadoProcesado: "",
                    estado: "",
                    desdeFechaEmision: DesdeFechaEmisionDocsDefault,
                    hastaFechaEmision: null,
                    desdeFechaProcesado: null,
                    hastaFechaProcesado: null,
                  })
                }
              >
                Borrar Filtros
              </Button>
            </div>
            <div>
              <Form.Group controlId="formBuscar" className="formSearch">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    name="buscador"
                    value={filterData.buscador}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  ></Form.Control>
                  <InputGroup.Text>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Button
                variant="primary"
                className="btnFiltrar"
                onClick={() => {
                  onFilterSubmit();
                }}
              >
                Filtrar
              </Button>
            </div>
          </div>
          <Form className="form">
            {/* Para el Modal de seleccionar el tipo de documento */}
            <div className="formsText">
              <Form.Group controlId="formTipoDoc" className="formGroup">
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="tipoDoc"
                    value={filterData.tipoDoc}
                    onChange={handleInputChange}
                  >
                    <option value="" className="default-option-select">
                      Tipo de Documento
                    </option>
                    <option value={1}>Factura Electrónica</option>
                    <option value={5}>Nota de Crédito</option>
                    <option value={4}>Autofactura</option>
                  </Form.Control>
                  <InputGroup.Text>
                    <IoDocumentText />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formEstablecimiento" className="formGroup">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Establecimiento"
                    name="establecimiento"
                    value={filterData.establecimiento}
                    onChange={handleInputChange}
                  ></Form.Control>
                  <InputGroup.Text>
                    <HiMiniBuildingStorefront />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formPuntoEmision" className="formGroup">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Punto de Emisión"
                    name="puntoEmision"
                    value={filterData.puntoEmision}
                    onChange={handleInputChange}
                  ></Form.Control>
                  <InputGroup.Text>
                    <FaComputer />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formEstadoInicial" className="formGroup">
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="estadoInicial"
                    value={filterData.estadoInicial}
                    onChange={handleInputChange}
                  >
                    <option value="" className="default-option-select">
                      Estado Inicial
                    </option>
                    <option value={0}>No Enviado</option>
                    <option value={1}>Enviado</option>
                  </Form.Control>
                  <InputGroup.Text>
                    <BsFillSendFill />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formEstadoProcesado" className="formGroup">
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="estadoProcesado"
                    value={filterData.estadoProcesado}
                    onChange={handleInputChange}
                  >
                    <option value="" className="default-option-select">
                      Estado Procesado{" "}
                    </option>
                    <option value={0}>Aprobado</option>
                    <option value={1}>Aprobado con Observación</option>
                    <option value={2}>Rechazado</option>
                    <option value={3}>En Proceso</option>
                  </Form.Control>
                  <InputGroup.Text>
                    <PiMagnifyingGlassFill />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formEstado" className="formGroup">
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="estado"
                    value={filterData.estado}
                    onChange={handleInputChange}
                  >
                    <option value="" className="default-option-select">
                      Estado
                    </option>
                    <option value={0}>Inactivo</option>
                    <option value={1}>Activo</option>
                    <option value={2}>Cancelado</option>
                    <option value={3}>Inutilizado</option>
                    <option value={4}>Devuelto</option>
                  </Form.Control>
                  <InputGroup.Text>
                    <TbSquareCheckFilled />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="formFechaDesdeEmi"
                className="formGroupDate"
              >
                <DatePicker
                  selected={filterData.desdeFechaEmision}
                  onChange={handleDateDesdeEmiChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Desde Emision"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown
                  customInput={
                    <CustomInputDate isInitial={true}></CustomInputDate>
                  }
                />
              </Form.Group>

              <Form.Group
                controlId="formFechaHastaEmi"
                className="formGroupDate"
              >
                <DatePicker
                  selected={filterData.hastaFechaEmision}
                  onChange={handleDateHastaEmiChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Hasta Emision"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown
                  customInput={
                    <CustomInputDate isInitial={false}></CustomInputDate>
                  }
                />
              </Form.Group>

              <Form.Group
                controlId="formFechaDesdeProc"
                className="formGroupDate"
              >
                <DatePicker
                  selected={filterData.desdeFechaProcesado}
                  onChange={(date) => handleDateDesdeProcChange(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Desde Procesado"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown
                  customInput={
                    <CustomInputDate isInitial={true}></CustomInputDate>
                  }
                />
              </Form.Group>

              <Form.Group
                controlId="formFechaHastaProc"
                className="formGroupDate"
              >
                <DatePicker
                  selected={filterData.hastaFechaProcesado}
                  onChange={handleDateHastaProcChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Hasta Procesado"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown
                  customInput={
                    <CustomInputDate isInitial={false}></CustomInputDate>
                  }
                />
              </Form.Group>
            </div>
          </Form>
        </div>
      ) : (
        <div className="divButtonAddFilter">
          <div>
            <Button variant="dark" onClick={showFilter} className="btnFiltros">
              Filtros <FaCaretDown />
            </Button>
          </div>
          <div>
            <Form.Group controlId="formBuscar" className="formSearch">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Buscar"
                  name="buscador"
                  value={filterData.buscador}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                ></Form.Control>
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Button
              variant="primary"
              className="btnFiltrar"
              onClick={() => {
                onFilterSubmit();
              }}
            >
              Filtrar
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterForm;
