
import apiConfig from "../../services/apiConfig";

import { useState, useEffect, useContext } from "react";

import axios from 'axios';
import { AuthContext } from "./AuthContext";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      const { tokenExpiration } = userData;
      const isTokenExpired = isExpired(tokenExpiration);

      if (!isTokenExpired) {
        setUser(userData);
      } else {
        // Si el token ha expirado, limpia los datos del usuario almacenados en localStorage
        localStorage.removeItem('userData');
      }
    }
  }, []);

  const login = async (email, password) => {
    try {

      var body ={
        Email: email,
        Password : password
      }

      const response = await axios.post(`${apiConfig.baseUrl}/api/usuario/login`, body);

      const { fullName, roles, email: userEmail, token, tokenExpiration } = response.data;

      const userData = { fullName, roles, email: userEmail, token, tokenExpiration };
      setUser(userData);

      // Guarda los datos del usuario en localStorage para persistencia
      localStorage.setItem('userData', JSON.stringify(userData));
    } catch (error) {
      setError('Credenciales inválidas');
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setError(null);
    // Limpia los datos del usuario almacenados en localStorage al cerrar sesión
    localStorage.removeItem('userData');
  };

  const isExpired = (expiration) => {
    const tokenExpiration = new Date(expiration);
    const currentDateTime = new Date();
    return tokenExpiration <= currentDateTime;
  };

  return { user, error, login, logout, ...authContext };
};

export default useAuth;










