import { EstadosEnum, EstadosInicialEnum, EstadosProcesoEnum } from "./enums";

export const formatData = (data) => {
    return data.map(item => ({
        ...item,
        fechaEmision: formatDate(item.fechaEmision),
        fechaProceso: formatDate(item.fechaProceso),
        estadoInicialDesc: formatEstadoInicial(item.estadoInicial),
        numeroDocumento: item.numeroDocumento.toString().padStart(8, '0'),
        totalDoc: formatNumber(item.totalDoc),
        estadoProcesoDesc: formatEstadoProceso(item.estadoProceso),
        estadoDesc: formatEstado(item.estado),
        natCliente: formatNaturalezaReceptor(item.natCliente)
    }));
};

//Formateos
export const formatDate = (dateString) => {
    if (dateString === null) {
        return null
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 porque los meses en JS van de 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDateTime = (fecha) => {
    if (fecha === null) {
        return null
    }
    const date = new Date(fecha);
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const año = date.getFullYear();
    const horas = String(date.getHours()).padStart(2, '0');
    const minutos = String(date.getMinutes()).padStart(2, '0');

    return `${dia}/${mes}/${año} ${horas}:${minutos}`;
};

export const formatEstadoInicial = (estadoInicial) => {
    switch (estadoInicial) {
        case EstadosInicialEnum.NoEnviado:
            return "No Enviado"
        case EstadosInicialEnum.Procesado:
            return "Procesado"
        case EstadosInicialEnum.EnSifen:
            return "En SIFEN"
        default:
            return ""
    }
};

export const formatEstadoProceso = (estadoProceso) => {
    switch (estadoProceso) {
        case EstadosProcesoEnum.Aprobado:
            return "Aprobado"
        case EstadosProcesoEnum.AprobadoConObservacion:
            return "Aprobado con observación"
        case EstadosProcesoEnum.Rechazado:
            return "Rechazado"
        case EstadosProcesoEnum.EnProceso:
            return "En Proceso"

        default:
            return ""
    }
};

export const formatEstado = (estado) => {
    switch (estado) {
        case EstadosEnum.Inactivo:
            return "Inactivo"
        case EstadosEnum.Activo:
            return "Activo"
        case EstadosEnum.Cancelado:
            return "Cancelado"
        case EstadosEnum.Inutilizado:
            return "Inutilizado"
        case EstadosEnum.Devuelto:
            return "Devuelto"

        default:
            return ""
    }
};

export const formatNumber = (number) => {
    let opciones = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
    return number.toLocaleString('es-ES', opciones);
}

const formatNaturalezaReceptor = (naturaleza) => {
    if (naturaleza === 1) {
        return "Contribuyente"
    } else if (naturaleza === 2) {
        return "No Contribuyente"
    }
}

