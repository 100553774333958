import TextBox from "./TextBox";
import "../styles/modal-details-styles.css";

const ItemDetailDE = (props) => {
    return(
        <div className="labelText">
            <div>
                <p>{props.title}:</p>
            </div>
            <TextBox value={props.value} variant={props.loading ? "dark" : ""} />
        </div>
    )
}

export default ItemDetailDE;