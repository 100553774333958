import React from "react";
import { Modal } from "react-bootstrap";
import "../styles/loader-styles.css";

const ModalLoading = ({ loading }) => {
  return (
    <Modal
      show={loading}
      centered
      backdrop="static"
      contentClassName="modal-loading"
    >
      <Modal.Body className="modal-body-loading">
        <div className="loaderContainerModal">
          <div className="loader" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLoading;
