import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import styles from "../styles/table-invoice-styles.css";
import { formatData } from "../utils/dataFormatter";
import ModalDetailsDE from "./ModalDetailsDE";
import { TiposDocEnum } from "../utils/enums";

const TableInvoice = ({ data, handleRefresh, loading, setLoading }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const selectedItemRef = useRef(selectedItem);

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalVisible(false);
  };

  useEffect(() => {
    if (
      selectedItemRef.current !== null &&
      selectedItemRef.current !== undefined
    ) {
      setSelectedItem(
        formatData(data).find((item) => item.id === selectedItemRef.current.id)
      );
      setModalVisible(true);
    }
  }, [data]);

  useEffect(() => {
    selectedItemRef.current = selectedItem;
  }, [selectedItem]);

  return (
    <>
      <Table striped bordered hover size="sm" style={styles}>
        <thead>
          <tr>
            {/*<th>#</th>*/}
            <th>Tipo Documento</th>
            <th>Doc. Num.</th>
            <th>Serie</th>
            <th>Est.</th>
            <th>P. Emi.</th>
            <th>CDC</th>
            <th>Fecha Emision</th>
            <th>Estado Inicial</th>
            <th>Pago Id</th>
            <th>Cliente</th>
            {/*<th>Moneda</th>*/}
            <th>Doc. Total</th>
            <th>Fecha Procesado</th>
            <th>Estado Procesado</th>
            <th>Descripcion Procesado</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {formatData(data).map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item)}>
              {/*<td>{index}</td>*/}
              <td className="td-tipoDoc">{item.tipoDoc}</td>
              <td>{item.numeroDocumento}</td>
              <td>{item.serieDoc}</td>
              <td>{item.establecimiento}</td>
              <td>{item.puntoEmision}</td>
              <td>{item.cdc.slice(-10)}</td>
              <td>{item.fechaEmision}</td>
              <td>{item.estadoInicialDesc}</td>
              <td>{item.pagoId}</td>
              <td className="td-cliente">
                {item.camposProcesadoDE.ITiDE === TiposDocEnum.AutoFactura ? item.nombreVendedorAFE : item.nombreCliente}</td>
              {/*<td>{item.monedaDoc}</td>*/}
              <td>{item.totalDoc}</td>
              <td>{item.fechaProceso}</td>
              <td>{item.estadoProcesoDesc}</td>
              <td className="td-descripcionProc">{item.descripcionProceso}</td>
              <td>{item.estadoDesc}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isModalVisible && (
        <ModalDetailsDE
          show={true}
          item={selectedItem}
          onClose={handleCloseModal}
          handleRefresh={handleRefresh}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default TableInvoice;
