import React, { useContext, useEffect, useState } from 'react';
import TableInvoice from '../components/TableInvoice';
import { getDEs } from '../services/dataService';
import Head from '../components/layout/head/Head';
import { AuthContext } from '../hooks/auth/AuthContext';
import '../styles/home-styles.css'
import '../styles/loader-styles.css'
import FilterForm from '../components/Filter/FilterForm';
import ModalLoading from '../components/ModalLoading';
import PaginationNav from '../components/PaginationNav';
import { getData } from "../utils/dataGetter";
import EmptyMessage from '../components/EmptyMessage';
import { FechaDesdeEmisionDocsDefault } from '../utils/utils';
import { config } from '../utils/config';

const Home = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [filterData, setFilterData] = useState({
        buscador: "",
        tipoDoc: "",
        establecimiento: "",
        puntoEmision: "",
        estadoInicial: "",
        estadoProcesado: "",
        estado: "",
        desdeFechaEmision: FechaDesdeEmisionDocsDefault,
        hastaFechaEmision: null,
        desdeFechaProcesado: null,
        hastaFechaProcesado: null,
    });
    const [filterDataPrev, setFilterDataPrev] = useState({
        buscador: "",
        tipoDoc: "",
        estadoInicial: "",
        estadoProcesado: "",
        estado: "",
        desdeFechaEmision: null,
        hastaFechaEmision: null,
        desdeFechaProcesado: null,
        hastaFechaProcesado: null,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = config.CANT_DOCS_PER_PAGE;

    const authContext = useContext(AuthContext)
    var { user } = authContext;

    const handleSetFilterData = (data) => {
        setFilterDataPrev(filterData);
        setFilterData(data);
    }
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };

    const handleLoadDataTable = async () => {
        try {
            setLoading(true)
            var opciones = filterData;

            if (filterData !== filterDataPrev) {
                opciones.currentPage = 1;
                setFilterDataPrev(filterData);
                setCurrentPage(1);
            } else {
                opciones.currentPage = currentPage;
            }

            opciones.pageSize = itemsPerPage;

            const fetchedData = await getDEs(opciones, user.token);

            setData(getData(fetchedData.items));
            setTotalItems(fetchedData.totalItems);
            setLoading(false);
        } catch (error) {
            console.error("Error load data:", error);
            setLoading(false);
        }
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (refresh) {
            handleLoadDataTable();
            setRefresh(false);
        }
    }, [refresh]);

    // eslint-disable-next-line
    useEffect(() => {
        handleLoadDataTable();
    }, [user.token]);

    return (
        <>
            <Head title="Dashboard" />
            <ModalLoading loading={loading} />
            <div className='container-home'>
                <FilterForm filterData={filterData} setFilterData={handleSetFilterData} onFilterSubmit={handleLoadDataTable} loading={loading}></FilterForm>
                {data.length !== 0 ? <TableInvoice data={data} handleRefresh={setRefresh} setLoading={setLoading} loading={loading}></TableInvoice> : <><TableInvoice data={data} handleRefresh={setRefresh} setLoading={setLoading} loading={loading}></TableInvoice><EmptyMessage /></>}
                <PaginationNav currentPage={currentPage} handleCurrentPage={handleCurrentPage} itemsPerPage={itemsPerPage} totalItems={totalItems}></PaginationNav>
            </div>
        </>
    );
}

export default Home;
